import Button from 'components/Button'
import useNF from 'hooks/queries/useNF'

interface NFReprocessamento {
  nfId: string
}

export default function NFReprocessamento({ nfId }: NFReprocessamento) {
  const { useReprocessarNF } = useNF()
  const { mutateAsync: reprocessarNF, isLoading } = useReprocessarNF()

  async function handleReprocessarNF() {
    await reprocessarNF(nfId)
  }

  return (
    <Button
      fullWidth
      isLoading={isLoading}
      onClick={handleReprocessarNF}
      sx={{ whiteSpace: 'nowrap' }}
    >
      Reprocessar Tributação
    </Button>
  )
}
